.profile-container {
    position: relative;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .profile-image:hover {
    transform: scale(1.1);
  }
  
  .profile-popup-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #fff;  /* change this to another color */
    color: #000;  /* change this to another color */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 200px;
  }
  
  .profile-popup-item {
    padding: 10px 20px;
    transition: background-color 0.3s;
  }
  
  .profile-popup-item.logout:hover {
    background-color: #f2f2f2;
  }
  
  .logout {
    cursor: pointer;
  }
  
.product-info {
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .product-info-row1 {
    display: flex;
    align-items: center;
  }
  
  .product-info-row2 {
    margin-top: 10px;
    display: flex;
    align-items: left;
  }
  
  .product-info img {
    width: 50px;
    height: 50px;
    border-radius: 8%;
    margin-right: 10px;
  }
  
  .product-info h2 {
    margin: 0;
  }
  
  
  .product-info button {
        min-height: 40px; /* 최소 높이 설정 */
        width: 120px; 
        margin: 1px;
        font-size: 16px;
        background-color: #2E3A59;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        border-radius: 8px;
        font-size: 12px;
      }
  
  .product-info button:hover {
    background-color: #555;
  }
  
.chatview .container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--tabbar-height) - var(--text-input-height));
  margin-top: calc(var(--navbar-height) + 10px);
  margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 20px);
  max-width: 80%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}


.chatview header {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  background-color: var(--primary-bg-color);
  height: var(--header-height);
  min-height: 50px;
  color: white;
  position: fixed;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.chatview section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; 
  width: 100%;
  background-color: #EFEFF1;
}

.chatview .chat-box {
  flex: 1;
  overflow-y: auto;
  margin:  1px;
  padding: 10px;
  background-color: #EFEFF1;
  /*border-radius: 5px;
  box-shadow: var(--box-shadow); */
}


.chatview .chat-box::-webkit-scrollbar {
  width: 0.25rem;
}

.chatview .chat-box::-webkit-scrollbar-track {
  background: #1e1e24;
}

.chatview .chat-box::-webkit-scrollbar-thumb {
  background: #6649b8;
}


.chatview form {
  z-index: 1001;
  flex-shrink: 0;
  height: var(--text-input-height);
  min-height: 30px;  /* 최소 높이 설정 */
  width: 100%;  
  position: fixed;
  bottom: var(--tabbar-height);
  padding: 0;
  background-color: #2E3A59;
  display: flex;
  font-size: 1.5rem;
  left: 50%;
  transform: translateX(-50%); /* 가운데 정렬 */
 
}

.chatview input {
  flex: 1;  
  min-height: 30px;
  width: 100%;  /* 화면 전체 넓이로 설정 */
  font-size: 1.2rem;
  background: #FFFFFF;
  color: black;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0px;
  padding: 0 10px;
  transition: border 0.2s;
  resize: none;
}

.chatview button {
  min-height: 40px; /* 최소 높이 설정 */
  width: 85px; 
  margin: 1px;
  font-size: 12px;
  background-color: #2E3A59;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 1px;
}
.chatview button:hover {
  background-color: #394A70;  /* A darker shade for hover */
}
.chatview button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.chatview input:focus {
  border-color: #2E3A59; /* A subtle blue border on focus */
}

.chatview .sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

.chatview ul, .chatview li {
  text-align: left;
  list-style: none;
}

.chatview p {

  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.chatview .message {
  display: flex;
  margin: 10px 0;
  overflow: visible;
}

.chatview .sent {
  flex-direction: row-reverse;
}

.chatview .sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}

.chatview .received p {
  background: #e5e5ea;
  color: black;
}

.chatview .appointments {
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatview .appointments p {
  color: white;
  background: #f20bad;
  align-self: center;
}


.chatview img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 1px;
}

/* media-previews container */
.chatview .media-previews {
  display: flex;                /* 아이템들을 가로로 나열 */
  flex-wrap: wrap;             /* 필요하다면 다음 줄로 아이템들을 넘김 */
  gap: 1px;                   /* 아이템들 사이의 간격 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  align-items: center;         /* 아이템들을 중앙에 위치시킴 */
  padding: 1px;               /* 내부 패딩 */
  border: 1px solid #e0e0e0;   /* 테두리 */
  border-radius: 8px;          /* 모서리 라운드 처리 */
}

/* media-previews items: images and videos */
.chatview .media-previews img, 
.chatview .media-previews video {
  max-width: 100px;          /* 최대 너비 */
  max-height: 100px;         /* 최대 높이 */
  object-fit: cover;         /* 이미지나 비디오가 요소 내에서 잘리지 않고 꽉 차게 표시 */
  border-radius: 4px;        /* 아이템의 모서리 라운드 처리 */
}

/* Optional: if you want a hover effect for images */
.chatview .media-previews img:hover {
  transform: scale(1.05);    /* 이미지를 약간 확대 */
  transition: transform 0.3s ease; /* 부드러운 확대 효과 */
}

/*파일 업로드*/
.chatview .progress-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
  border-radius: 10px;
}

.chatview .circular-progress {
  position: relative;
  width: 100px;
  height: 100px;
}

.chatview .circle-bg,
.chatview .circle {
  fill: none;
  stroke-width: 6;
  cx: 50%;
  cy: 50%;
  r: 44; /* r=45에서 선의 두께 절반인 3을 빼서 42로 설정 */
}

.chatview .circle-bg {
  stroke: #eee;
}

.chatview .circle {
  stroke: #f88;  /* 변경된 색상 */
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.chatview .progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}
/*새로운 프로그래스*/
.chatview .progress-container {
  padding: 1em;
  background-color: #f4f4f4;
}

.chatview .total-progress,
.chatview .file-progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  margin: 0.5em 0;
}

.chatview .progress-bar,
.chatview .file-progress-bar {
  height: 100%;
  background-color: #007BFF;
}

.chatview .individual-file-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.chatview .file-progress-bar-container {
  width: 60%; /* 바의 너비를 조절하십시오. */
  margin-left: 1em;
}


/*파일선택시 프리뷰 */
.chatview .file-previews {
  display: flex;
  gap: 10px;
  position: fixed;
  bottom: calc(var(--tabbar-height) + var(--text-input-height) + 5px);
  left: 0;
  right: 0;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3); /* adding a semi-transparent background */
}

.chatview .file-preview {
  position: relative;
  width: 80px;
  height: 60px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.chatview .file-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatview .file-preview button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2E3A59;
  color: white;
  border: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 1;
  font-size: 10px;
  padding: 0; 
  line-height: 1;
  box-sizing: border-box;
}

.chatview .close-all-button {
  position: absolute;
  top: 5px;
  right: 5px;
  max-width: 25px;
  max-height: 15px;
  padding: 2px 5px; /* padding 값을 줄여서 버튼 크기를 작게 합니다. */
  font-size: 0.8rem; /* 글자 크기를 줄입니다. */
  background-color: #2E3A59;
  color: white;
  border: none;
  border-radius: 3px; /* border-radius 값을 조절하여 모서리를 둥글게 합니다. */
  cursor: pointer;
}








/* ... (기존의 CSS 코드) ... */

@media (max-width: 768px) {
  .chatview header {
      padding: 10px;
  }
  .chatview .container {
    max-width: 100%;
  }
  .chatview .chat-box {
    margin-top: var(--navbar-height);
    /* 반응형 디자인에서도 채팅 영역의 높이를 navbar, tabbar를 제외한 나머지 화면 높이로 설정 */
    height: calc(100vh - var(--navbar-height) - var(--tabbar-height)*2);
    overflow-y: scroll; /* 스크롤 영역을 해당 chat-box 내로 한정 */
  }

  .chatview form {
    font-size: var(--font-small);
    padding: 0;
  }

  .chatview input, button {
    min-height: 30px;
    font-size: var(--font-small);
  }
  .chatview button {
      width: 50px;
      font-size: 10px;
  }
  .chatview p {
      padding: 5px 15px;
      max-width: 400px;
  }
  .chatview .message {
      margin: 2px 0;
  }
  .chatview img {
      width: 30px;
      height: 30px;
      margin: 0 5px;
  }
}


.detail .container {

    margin-top: calc(var(--navbar-height) + 10px);
    margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);  /*tabbar의 높이 */
    margin-left:auto;
    margin-right:auto;
    padding: 15px;
    max-width: 100%;  /* 최대 너비를 90%로 설정. 원하는 비율로 조절 가능. */
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 5px;
}

.detail .product-container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh-calc(var(--tabbar-height) + var(--text-input-height) + 10px);

}

.detail .product {
    flex: 0 1 calc(20% - 20px); /* 25%를 사용하면 한 줄에 4개의 상품이 나옵니다. 이를 조절하여 원하는 수로 변경 가능 */
    margin-bottom: 20px; /* 각 상품 아래의 여백 */
    flex-direction: column;
    display: flex;
  
    transition: box-shadow 0.3s, transform 0.3s;  /* 애니메이션 효과 추가 */
    cursor: pointer;  /* 마우스 포인터가 셀 위로 올라올 때 손가락 모양으로 바뀝니다. */
  }
  
  .detail  .product:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);  /* 볼록한 그림자 효과 */
    transform: scale(1.02);  /* 약간 확대 */
    border-radius: 8px;
  }
  
  .detail .product-info {
    padding: 25px;  /* 패딩을 더 크게 설정 */
    border-top: 2px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 30px;
}

.detail .detail-container {
  display: flex;
  /* max-width: 1200px;
  border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
}

.detail .left-column {
  flex: 2;
  padding: 20px;
  background-color: #f0f0f0; /* Replace with the desired background color */
}

.detail .right-column {
  flex: 1;
  padding: 20px;
}


.detail .author, .detail .title, .detail .date, .detail .price {
    margin-bottom: 15px;  /* 간격을 더 크게 설정 */
    font-size: 1.1em;  /* 폰트 사이즈 늘리기 */
    text-align: left;
}

.detail .views, .detail .chats {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-size: 20px;
    color: #333;
}

.detail .action-buttons {
    display: flex;
    justify-content: space-between;
}

.detail .action-button {
    flex: 1;
    margin: 0 15px;  /* 마진을 더 크게 설정 */
    padding: 15px;  /* 패딩을 더 크게 설정 */
    font-size: 1.2em;  /* 버튼의 폰트 사이즈 늘리기 */
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 5px; /* 버튼 라운드 효과 추가 */
    background-color: #f5f5f5; /* 버튼 배경색 변경 */
}

.detail .action-button:hover {
    background-color: #e0e0e0; /* 호버 배경색 변경 */
    transform: translateY(-2px); /* 호버 시 약간 위로 움직이는 효과 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 버튼의 그림자 효과 추가 */
}

.detail .flex-end {
    display: flex;
    justify-content: flex-end;
}
#map {
    z-index: 1000; /* 예시: 더 높은 값으로 설정 */
}
.detail .bottom-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--tabbar-height);
    height: var(--text-input-height);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;  /* 다른 요소 위에 표시되도록 함 */
}

.detail .like-button {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px; 
    width: 50%;
    margin-right: 1%;  /* 오른쪽 여백을 1%로 설정 */
}

.detail .chat-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* 오른쪽 정렬 */
    gap: 8px;
    cursor: pointer;
    width: 50%;
    margin-right: 1%;  /* 오른쪽 여백을 1%로 설정 */
}
.detail .action-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* 오른쪽 정렬 */
    gap: 8px;
    cursor: pointer;
    width: 50%;
}
.detail .like-button, .detail .chat-button, .detail .action-button {
    display: flex;
    justify-content: center;
    text-align: center;
    /* line-height를 버튼의 높이에 맞게 조절해주면 수직으로도 가운데 정렬이 됩니다. */
    line-height: 50px; /* 이 값은 버튼의 높이에 따라 조절 필요 */
    /* 기존 스타일 */
}
.detail .float-button {
    position: fixed;
    bottom: calc(var(--tabbar-height) + 30px);
    right: 50px;        /* 20 pixels from the right side */
    width: 140px;    /* Width of the button */
    height: 60px;   /* Height of the button */

    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgba(46, 58, 89, .84), rgba(57, 31, 91, .84) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,rgba(46, 58, 89, 0.4) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand,sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: 0;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
  }
  
  .detail .float-button:hover {
    background-image: linear-gradient(#B384C9, #391F5B 50%);
  }
  



/*파일선택시 프리뷰 */
.detail .image-previews {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3); /* adding a semi-transparent background */
}

.detail .image-preview {
  position: relative;
  width: 80px;
  height: 60px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.detail .image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail .image-preview-container.selected {
  border: 2px solid blue; /* or any other styling you'd like */
}

  @media (min-width: 768px) {
    .float-button {
      font-size: 14px;
      padding: 18px 34px;
    }
  }
/* 기본적으로 5개의 아이템을 한 줄에 표시 */
.detail .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px; /* 아이템 간의 간격 */
  }
  
  /* 화면 크기가 1200px 미만일 때 4개의 아이템을 한 줄에 표시 */
  @media (max-width: 1800px) {
    .detail .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* 화면 크기가 900px 미만일 때 3개의 아이템을 한 줄에 표시 */
  @media (max-width: 1500px) {
    .detail .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* 화면 크기가 600px 미만일 때 2개의 아이템을 한 줄에 표시 */
  @media (max-width: 1200px) {
    .detail .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* 화면 크기가 400px 미만일 때 1개의 아이템만 한 줄에 표시 */
  @media (max-width: 800px) {
    .detail .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }
  
  
  /* Responsive design for tablets and mobile */
  @media (max-width: 800px) {
      .detail .container {
      margin-top: calc(var(--navbar-height) + 10px);
      margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px); 
      margin-left:auto;
      margin-right:auto;
      padding: 15px;
      max-width: 100%;
    }
     
    /* .detail .container, .detail .list-container {
      padding: 5px;
    } */
  

  
     .detail .product {
      flex: 1 1 100%; 
    } 
  
    /* .detail .thumbnail {
      width: 90%;  
      height: 60px; 
      margin-right: 15px;  
      margin-bottom: 0;
    } */
  
  }
  
@media (max-width: 768px) {
   /* .detail .detail-container {
        max-width: 100%;  
        padding: 10px;
    }  */
    .detail .detail-container {
      max-width: 100%;  
      padding: 5px;
      flex-direction: column; /* 컬럼 방향으로 변경 */
    }
  
    .detail .left-column {
      flex: none; /* 기본 flex 설정 해제 */
      width: 100%; /* 너비를 100%로 설정 */
    }
  
    .detail .right-column {
      flex: none; /* 기본 flex 설정 해제 */
      width: 100%; /* 너비를 100%로 설정 */
      order: 1; /* left-column 아래에 위치하도록 순서 변경 */
    }
    .detail .product-info {
        padding: 15px;
    }

    .detail .author, .detail .title, .detail .date, .detail .price {
        margin-bottom: 10px;  /* 간격을 줄입니다 */
        font-size: 1em;  /* 폰트 사이즈 조절 */
    }

    .detail .action-buttons {
        flex-direction: column; /* 버튼들을 수직으로 정렬합니다 */
    }

    .detail .action-button {
        margin: 10px 0;  /* 마진을 조절하여 버튼 간의 공간을 확보합니다 */
        padding: 10px;  /* 패딩 줄이기 */
        font-size: 1em;  /* 폰트 사이즈 조절 */
    }
}


/* Layout.css */

/* MiniNavbar.css */
.mini-navbar {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke !important;
    color: #2E3A59;
    padding: 0px 10px;         /* 상하 5px, 좌우 10px 패딩 */
    display: flex;             /* 항목들을 한 줄에 나열 */
    justify-content: space-between; /* 항목들을 넓게 분산 */
    align-items: center;      /* 항목들을 중앙 정렬 */
    position: relative; /* 절대 위치 지정 */
    top: 5px;          /* 상단 위치 조절 */

    right: 0px;        /* 오른쪽 위치 조절 */
    z-index: 1001;     /* Navbar보다 위에 나타나게 설정 */
    font-size: 0.5rem;
    height: 10px;
}

.mini-navbar a {
    color: #2E3A59; /* 텍스트 색상 */
    margin: 0 10px; /* 좌우 10px 마진 */
    text-decoration: none; /* 밑줄 제거 */
}
.navbar {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    /* subtle shadow for depth */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    position: relative;

    /* 상단에 고정 */
    /* top: 10; */
    /* 화면의 맨 위에서 시작 */
    /* left: 50; */
    /* 화면의 왼쪽에서 시작 */
    width: 100%;

    /* 화면 전체 너비로 설정 */
    z-index: 1000;
    /* 다른 요소들 위에 오도록 z-index를 높게 설정 */

    /* 아래는 navbar 디자인 관련 스타일입니다. 필요에 따라 조절 가능 */
    background-color: whitesmoke !important;
    color: #2E3A59;

    height: calc(var(--navbar-height)+ 0px);
    /*Navbar의 높이*/

}

.navbar-collapse {
    background-color: whitesmoke !important;
}

/* 드롭다운 메뉴의 배경색 */
.navbar-nav .nav-item .dropdown-menu {
    background-color: whitesmoke;
}

/* 드롭다운 메뉴의 글씨색 */
.navbar-nav .nav-item .dropdown-menu .dropdown-item {
    color: #2E3A59;
}

/* 드롭다운 메뉴의 항목에 마우스를 올렸을 때의 배경색과 글씨색 */
.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    background-color: whitesmoke;
    color: #2E3A59;
}


.navbar img {
    height: 50px;
    /* Navbar 높이에 맞게 조절 */
    width: 50px;
    border-radius: 5%;
    /* 원형으로 표시 */
    object-fit: cover;
    /* 이미지가 지정한 너비와 높이에 맞게 조절되도록 함 */
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color:#2E3A59;
    /* Example font from Google Fonts */
}

.nav-item .nav-link {
    
    padding: 0.5rem 1rem;
    text-decoration: none; /* 언더바 제거 */
}
.nav-item .active-link {
    text-decoration: underline; /* 활성 링크의 언더바 추가 */
    
}
.nav-link.active {
    border-bottom: 2px solid #2E3A59;
}

.nav-link {
    text-decoration: none; /* 언더바 제거 */
}

.active-link {
    text-decoration: underline; /* 활성 링크의 언더바 추가 */
}

.nav-centered {
    display: flex;
    justify-content: space-between;
    /* 항목들 사이의 공간을 균등하게 배분합니다. */
    align-items: center;
    /* 항목들을 수직 중앙에 위치시킵니다. */
    width: 100%;
    /* Navbar의 전체 너비를 사용합니다. */
}

.left-nav,
.right-nav {
    flex: 0.2;
    /* 좌우 네비게이션 항목들이 30%의 너비를 차지하도록 설정 */
}

/* searchbar */
/* 검색바 컨테이너 스타일 */
.search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f2f5;
    border: 1px solid #ccd0d5;
    border-radius: 18px;
    padding: 5px 10px;
    max-width: 700px;
    max-height: 44px;
    flex: 0.6;
    margin: auto;
}

.hide {
    transform: translateY(-100%);
    transition: transform 0.3s ease;
}


.category-menu-button {
    width: 70px; /* Match the image width */
    height: 70px; /* Match the image height */
    padding: 0;
    border: none;
    background-color: transparent; /* Optional: Makes the button background transparent */
    cursor: pointer; /* Indicates the button is clickable */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; 

}

.category-menu-button img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the button area without distortion */
}

.search-input {
    border: none;
    padding: 1px;
    border-radius: 18px;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: auto 0;
}

.search-btn,
.mic-button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin: auto 0;
}

.search-btn:hover,
.mic-button:hover {}

.search-btn img,
.mic-button img {
    width: 20px;
    height: 20px;
}

.mic-button:hover {
    background-color: #e0e0e0;
    /* 버튼 위에 마우스를 올렸을 때의 배경색 */
}

.mic-button img {
    width: 24px;
    /* 이미지 크기 조절 */
    height: 24px;
    /* 이미지 크기 조절 */
}

/* 드랍다운메뉴 사라지게 처리 */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
 
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* .dropdown-content a:hover {
    background-color: #f1f1f1;
} */

.dropbtn:hover+.dropdown-content {
    display: block;
} 



/* 드롭다운 버튼 스타일 */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* 드롭다운 내용 컨테이너 */
.dropdown {
    position: relative;
    display: inline-block;
}

/* 드롭다운 내용 */
/* .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
} */

/* 드롭다운 내용 링크 스타일 */
.nav-link {
    color:#2E3A59;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* 링크에 마우스 호버 시 스타일 */
/* .nav-link:hover {background-color: #f1f1f1} */

/* 드롭다운 버튼에 호버 시 드롭다운 내용 표시 */
.dropdown:hover .dropdown-content {
    display: block;
}

/* 드롭다운 버튼에 호버 시 버튼 스타일 변경 */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.navbar-item {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.navbar-item:hover .dropdown-content {
    display: block;
}

/* 링크에 마우스 호버 시 스타일 */
.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #333; /* 호버 시 텍스트 색상 변경 */
}

.category-list {
    display: none; /* 기본적으로는 숨김 처리 */
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    padding: 12px 16px;
    border-radius: 4px;
    left: 0; /* Navbar.Brand 바로 아래에 위치 */
    top: 100%; /* Navbar.Brand의 높이 만큼 아래에 위치 */
  }
  
  .navbar-brand:hover + .category-list, /* Navbar.Brand에 호버 시 */
  .category-list:hover { /* 카테고리 리스트에 호버 시 */
    display: block; /* 리스트를 보여줌 */
  }

  .close-button {
    position: absolute;
    color: black;
    top: 1px;
    right: 1px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}
@media (max-width: 768px) {
    .navbar-brand {
        font-size: 1.2rem;
        /* 브랜드 로고의 크기를 줄입니다 */
    }

    .nav-item .nav-link {
        padding: 0.7rem 1.2rem;
        /* 네비게이션 아이템 간의 간격을 늘립니다 */
    }
}
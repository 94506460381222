/* ScheduleAppointment.css */

/* Modal Content */
.ScheduleAppointment .modal-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.2);
    padding: 40px 60px ;
    width: 800px ;
    border-radius: 10px ; 
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2); /* 그림자 효과를 약간 강화 */
    z-index: 1000;
    text-align: center; /* 내용을 중앙 정렬 */
}


/* Modal Overlay */
 .ScheduleAppointment .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    z-index: 1000;
}

.ScheduleAppointment form div {
    margin: 20px 0; 
} 

.ScheduleAppointment button {
    background-color: #2E3A59; /* 버튼의 배경색을 파란색으로 설정 */
    border: none;
    color: white;
    padding: 10px 20px; /* 버튼의 패딩 조절 */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; /* 버튼의 배경색을 부드럽게 변경하기 위한 트랜지션 추가 */
}

.ScheduleAppointment button:hover {
    background-color: #0056b3; /* 버튼에 마우스를 올렸을 때의 배경색 */
}
.ScheduleAppointment .close-button {
    position: absolute;
    top: 10px;
    right: 10px; /* right 값을 10px로 수정 */
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    z-index: 1010; /* Z-index 값 추가 */
}
.ScheduleAppointment label {
    margin-right: 10px; /* 원하는 공백 크기로 조정 */
    color: #fff;
    font-size: "24px";
}
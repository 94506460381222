/* App.css 또는 해당 컴포넌트의 CSS 파일에 추가 */

/* 주소 컨테이너 */
.address-container {
    font-family: Arial, sans-serif;
    /* max-width: 800px; */
    margin: 10 auto;
    width: 100%; /* 부모 컴포넌트의 넓이에 맞춤 */
}

/* 각 주소 카드의 스타일 */
.address-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
    position: relative;
}

/* 선택된 주소 카드 강조 */
.address-card.selected {
    border-color: #4CAF50;
    background-color: #f4f8f4;
}

/* 우편번호 찾기 및 기본 주소로 설정 버튼 스타일 */
.find-postcode-btn,
.set-default-btn {
    margin-right: 10px;
    padding: 5px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
}

.set-default-btn {
    background-color: #FF9800;
}

/* 주소 추가 및 변경 사항 저장 버튼 스타일 */
.add-address-btn,
.save-changes-btn {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px 0;
    background-color: #2196F3;
    color: white;
}

.save-changes-btn {
    background-color: #4CAF50;
}

/* 주소 항목 내부의 flex container 스타일 */
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.flex-input {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 10px;
}

/* 선택된 주소 옆의 아이콘 (추가적인 아이콘 라이브러리 또는 이미지 필요) */
.address-card.selected::after {
    content: "✔"; /* 아이콘 라이브러리 또는 이미지 사용 시 이 부분 변경 */
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 24px;
    color: #4CAF50;
}

.address-selection {
    padding: 8px 12px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
}

.address-selection.selected {
    background-color: #f5f5f5;
    border: 1px solid #007bff;
    font-weight: bold;
    color: #007bff;
}

.address-selection:hover {
    border-color: #007bff;
    color: #007bff;
}
